export const SOCIAL_URL = {
  facebook: 'https://www.facebook.com/AFPPlanVital/?fref=ts',
  twitter: 'https://twitter.com/planvitalafp',
  instagram: 'https://www.instagram.com/planvitalafp/?hl=es-la',
  linkedin: 'https://www.linkedin.com/company/afp-planvital',
  youtube: 'https://www.youtube.com/channel/UCZOAjIwHUEJeBXaFXz5c-dA'
};

export const CONTACT_CENTER = {
  NUMBER: 800072072,
  MASK: '800 072 072'
};