import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'urlSan'
})
export class UrlSanPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(resourceUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(resourceUrl);
  }

}
