import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { APP_ROUTES } from '@constants/global.constants';
import { UtilProviderService } from '@providers/util/util.provider';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent implements OnInit, OnDestroy {

  public app_routes = APP_ROUTES;
  public goLanding = true;
  public currentURL = '/404';
  private event$: Subscription | undefined;

  constructor(
    private utilSV: UtilProviderService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe(
      (event: NavigationEvent) => {
        if (event instanceof NavigationStart) {
          this.currentURL = this.utilSV.stringReplaceClean(event.url);
          this.goLandingVal();
        }
      }
    );
  }

  ngOnInit(): void {
    this.currentURL = this.utilSV.stringReplaceClean(this.router.url);
    this.goLandingVal();
  }

  ngOnDestroy() {
    if (this.event$) {
      this.event$.unsubscribe();
    }
  }

  public navigate(path: string): void {
    this.utilSV.navigateToPath(path);
  }

  private goLandingVal() {
    switch (this.currentURL) {
      case '404':
        this.goLanding = true;
        break;
      case 'paymentorderform':
        this.goLanding = true;
        break;
      default:
        this.goLanding = false;
        break;
    }
  }

}
