import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from '@constants/global.constants';
import { UtilProviderService } from '@providers/util/util.provider';

@Component({
  selector: 'app-inquiries-button-panel',
  templateUrl: './inquiries-button-panel.component.html',
  styleUrls: ['./inquiries-button-panel.component.scss']
})
export class InquiriesButtonPanelComponent implements OnInit {

  public isMobile = false;
  public isResponsive = false;
  public inquiryUrl = APP_ROUTES.paymentOrderForm;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private utilSV: UtilProviderService
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe(result => {
        this.isResponsive = result.matches;
      });
  }

  public navigate(path: string): void {
    this.utilSV.navigateToPath(path);
  }

}
