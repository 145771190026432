import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { NAV_MENU_OPTIONS } from '@constants/menu.constants';
import { MenuOption } from '@interfaces/menu-option.interface';
import { UtilProviderService } from '@providers/util/util.provider';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  public menuOptions: Array<MenuOption> = [];
  public currentURL = '/home';
  private event$: Subscription | undefined;

  constructor(
    private utilSV: UtilProviderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getMenuOptions();
    this.event$ = this.router.events.subscribe(
      (event: NavigationEvent) => {
        if (event instanceof NavigationStart) {
          this.currentURL = this.utilSV.stringReplaceClean(event.url);
        }
      }
    );
  }

  ngOnInit(): void {
    this.currentURL = this.utilSV.stringReplaceClean(this.router.url);
  }

  ngOnDestroy() {
    if (this.event$) {
      this.event$.unsubscribe();
    }
  }

  private getMenuOptions(): void {
    this.menuOptions = NAV_MENU_OPTIONS;
  }

  public navigate(event: any, path: string): void {
    event.preventDefault();
    this.utilSV.navigateToPath(path);
  }

}
