import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilProviderService } from '@providers/util/util.provider';


@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formatRut]'
})
export class FormatRutDirective implements OnChanges {
  @Input('formatRut') public formControl: FormControl = new FormControl();

  constructor(
    private utilSV: UtilProviderService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControl'] && changes['formControl'].currentValue.value) {
      this.onChange(changes['formControl'].currentValue.value);
    }
  }

  @HostListener('input', ['$event.target.value']) public onChange(typedValue: string) {
    if (!typedValue) { return; }
    const rut = this.utilSV.rutFormat(typedValue);
    this.formControl.setValue(rut);
  }
}
