<div class="footer-container">
  <div class="footer general-container">
    <div class="info-contact">
      <p class="phone-number" (click)="callContactCenter()">
        <em class="i-phone"></em>
        <span>
          Contact Center <strong>{{ contactCenter.MASK }}</strong>
        </span>
      </p>
    </div>
    <div class="social-network">
      <div class="icon i-{{ socialNetwork }}" *ngFor="let socialNetwork of socialNetworks"
        (click)="goTo(socialUrls[socialNetwork])">
      </div>
    </div>
  </div>
</div>