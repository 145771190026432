import {Component, Input} from '@angular/core';
import { UtilProviderService } from '@providers/util/util.provider';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent {

  @Input() public top = '160px';

  constructor(
    private utilSV: UtilProviderService
  ) {
  }

  public getHelp(): void {
    this.utilSV.navigateToPath('help');
  }


}
