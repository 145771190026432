import { NgModule } from '@angular/core';
import { FormatRutDirective } from '@directives/format-rut/format-rut.directive';

@NgModule({
  exports: [
    FormatRutDirective,
  ],
  declarations: [
    FormatRutDirective,
  ],
  entryComponents: []
})
export class DirectivesModule { }
