
import { MenuOption } from '@interfaces/menu-option.interface';
import { APP_ROUTES } from './global.constants';

export const NAV_MENU_OPTIONS: Array<MenuOption> = [
  {
    label: 'Inicio',
    route: APP_ROUTES.home
  },
  {
    label: 'Preguntas Frecuentes',
    route: APP_ROUTES.faq
  },
  {
    label: 'Consulta de ordenes de pago',
    route: APP_ROUTES.paymentOrderInquiry
  },
  {
    label: 'Ley 21.484',
    route: APP_ROUTES.law
  },
];