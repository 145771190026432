import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@pipes/pipes.module';
import { MaterialModule } from 'app/material.module';
import { InquiriesButtonPanelComponent } from '../../pages/payment-order-inquiry/inquiries-button-panel/inquiries-button-panel.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { HelpHeaderComponent } from './help-header/help-header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HelpHeaderComponent,
    NavbarComponent,
    BreadcrumbsComponent,
    HelpButtonComponent,
    TopBarComponent,
    InquiriesButtonPanelComponent,
    ToolBarComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    MaterialModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HelpHeaderComponent,
    NavbarComponent,
    BreadcrumbsComponent,
    HelpButtonComponent,
    TopBarComponent,
    InquiriesButtonPanelComponent,
    ToolBarComponent,
  ]
})
export class ComponentsModule { }
