import { Component } from '@angular/core';
import { CONTACT_CENTER, SOCIAL_URL } from '@constants/footer.constants';
import { NavigationProviderService } from '@providers/navigation/navigation.provider';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public socialUrls = SOCIAL_URL;
  public socialNetworks = Object.keys(this.socialUrls);
  public contactCenter = CONTACT_CENTER;

  constructor(
    private navService: NavigationProviderService
  ) { }

  public goTo(url: string) {
    this.navService.goTo(url);
  }

  public callContactCenter() {
    document.location.href = `tel:${this.contactCenter.NUMBER}`;
  }

}
