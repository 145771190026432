export const AliMoney_HTTP_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, HEAD',
  'Access-Control-Allow-Headers': 'Content-Type, Accept, X-Requested-With, remember-me',
  'Content-Type': 'application/json',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'ALLOW-FROM http://localhost:8100'
};

export const APIGee_HTTP_HEADERS = {
  'Accept': '*/*',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-Content-Type-Options': 'nosniff'
};

export const APP_ROUTES = {
  main: '',
  notFound: '404',
  faq: 'faq', // LandingSite
  help: 'help', // LandingSite
  home: 'home', // LandingSite
  law: 'law', // LandingSite
  paymentOrderInquiry: 'inquiries',  // LandingSite
  paymentOrderForm: 'payment-order-form', // Iquiries Flux
  paymentOrderEmpty: 'payment-order-error', // Iquiries Flux
  paymentOrderStatus: 'payment-order-status' // Iquiries Flux
};
