import { Component, Input, OnInit } from '@angular/core';
import { NAV_MENU_OPTIONS } from '@constants/menu.constants';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() menuOpt: string | undefined;
  public selectedBreadcrumb = '';
  public breadcrumbText = '';

  ngOnInit(): void {
    this.selectedBreadcrumb = this.menuOpt ? this.menuOpt : '';
    NAV_MENU_OPTIONS.forEach(opt => {
      if (this.breadcrumbText.length == 0) {
        this.breadcrumbText = `${opt.label}`;
      } else {
        this.breadcrumbText = `${this.breadcrumbText} | ${opt.label}`;
      }
    });
    if (this.selectedBreadcrumb) {
      this.breadcrumbText = this.breadcrumbText.replace(
        this.selectedBreadcrumb, `<span style="font-weight: 600;color: #C21B17;">${this.selectedBreadcrumb}<span>`
      );
    }
    console.log('Breadcrumbs: ', this.breadcrumbText);
  }

}
