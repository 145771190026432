import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationProviderService {

  constructor(
    private navCtrl: NavController
  ) { }

  public externalUrl(url: string) {
    return url.includes('http');
  }

  public goTo(url: string) {
    if (this.externalUrl(url)) {
      this.windowOpen(url);
    } else {
      this.navCtrl.navigateForward(url);
      this.scrollHeader();
    }
  }

  public scrollHeader() {
    const element = document.getElementById('banner');
    if (element) { element.scrollIntoView(); }
  }

  public windowOpen(externalUrl: string, type = '_blank') {
    return window.open(externalUrl, type);
  }

  public reload() {
    window.location.reload();
  }

  public subscribeToReloadOnGoToBack() {
    window.addEventListener('popstate', this.reload);
  }
}
