import { Component } from '@angular/core';
import { NavigationProviderService } from '@providers/navigation/navigation.provider';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  constructor(private navService: NavigationProviderService) { }

  public goTo(): void {
    this.navService.goTo(environment.publicSite);
  }
}
