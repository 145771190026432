import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeScript } from '@angular/platform-browser';

@Pipe({
  name: 'scriptSan'
})
export class ScriptSanPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(resourceUrl: string): SafeScript {
    return this.sanitizer.bypassSecurityTrustScript(resourceUrl);
  }

}
