import { Component } from '@angular/core';

@Component({
  selector: 'app-help-header',
  templateUrl: './help-header.component.html',
  styleUrls: ['./help-header.component.scss']
})
export class HelpHeaderComponent {

}
