import { Component } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor() {
    this.addRecaptchaScript();
  }

  private addRecaptchaScript(): void {
    const recaptchaSrc = (document: any, s: string, id: string) => {
      // tslint:disable-next-line: prefer-const
      let js: any = document.getElementsByTagName(s)[0];
      const fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      js = document.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptchaSiteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk');
  }

}
