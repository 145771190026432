import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class Utils {
  constructor(){ /* TODO document why this constructor is empty */  }

  public compareStringUsingWildcard(template: string, stringToCompare: string): boolean {
    return new RegExp('^' + template.replace(/\*/g, '.*') + '$').test(stringToCompare);
  }

}
