const WILDCARD = '*';

export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  {
    route: `${WILDCARD}transfer/state${WILDCARD}`,
    method: 'GET',
    action: 'transferState'
  },
  {
    route: `${WILDCARD}certificates/validate-certificate${WILDCARD}`,
    method: 'GET',
    action: 'validateCertificate'
  },
  {
    route: `${WILDCARD}contact/register-bank-account${WILDCARD}`,
    method: 'POST',
    action: 'registerBankAccount'
  },
  {
    route: `${WILDCARD}contact/send-cai-form${WILDCARD}`,
    method: 'POST',
    action: 'sendCaiForm'
  },
  {
    route: `${WILDCARD}contact`,
    method: 'POST',
    action: 'contact'
  },
  {
    route: `${WILDCARD}certificates/certificates${WILDCARD}`,
    method: 'GET',
    action: 'getPublicCertificate'
  },
  {
    route: `${WILDCARD}forms/pensionaries/survival${WILDCARD}`,
    method: 'POST',
    action: 'survivalPensionRequest'
  },
  {
    route: `${WILDCARD}forms/family-allowance${WILDCARD}`,
    method: 'POST',
    action: 'familyAllowanceRequest'
  },
  {
    route: `${WILDCARD}forms/studies-accreditation${WILDCARD}`,
    method: 'POST',
    action: 'studiesAccreditationRequest'
  },
  {
    route: `${WILDCARD}forms/mortuary-fee${WILDCARD}`,
    method: 'POST',
    action: 'mortuaryFeeRequest'
  },
  {
    route: `${WILDCARD}forms/charging-fees${WILDCARD}`,
    method: 'POST',
    action: 'chargingFees'
  },
  {
    route: `${WILDCARD}forms/state-guarantee${WILDCARD}`,
    method: 'POST',
    action: 'stateGuaranteeRequest'
  },
  {
    route: `${WILDCARD}forms/inheritance${WILDCARD}`,
    method: 'POST',
    action: 'inheritanceRequest'
  },
  {
    route: `${WILDCARD}partial-forms/save${WILDCARD}`,
    method: 'POST',
    action: 'savePartialRequest'
  },
  {
    route: `${WILDCARD}partial-forms/load${WILDCARD}`,
    method: 'GET',
    action: 'loadPartialRequest'
  },
  {
    route: `${WILDCARD}partial-forms/public/paperwork-status${WILDCARD}`,
    method: 'GET',
    action: 'loadAllUserPaperwork'
  },
  {
    route: `${WILDCARD}partial-forms/detail${WILDCARD}`,
    method: 'GET',
    action: 'loadRequestDetail'
  },
  {
    route: `${WILDCARD}partial-forms/paperwork-files${WILDCARD}`,
    method: 'POST',
    action: 'uploadPapeworkFiles'
  },
  {
    route: `${WILDCARD}partial-forms/paperwork-files${WILDCARD}`,
    method: 'DELETE',
    action: 'deletePaperworkFile'
  },
  {
    route: `${WILDCARD}partial-forms/comments${WILDCARD}`,
    method: 'POST',
    action: 'postComment'
  },
  {
    route: `${WILDCARD}partial-forms/comments${WILDCARD}`,
    method: 'GET',
    action: 'getComments'
  },
  {
    route: `${WILDCARD}investor-profile/simulation-result`,
    method: 'POST',
    action: 'postSimulationResult'
  },
  {
    route: `${WILDCARD}investor-profile/simulation-result`,
    method: 'PATCH',
    action: 'updateSimulationResult'
  },
  {
    route: `${WILDCARD}alimony/defendants/${WILDCARD}/requests/${WILDCARD}/status`,
    method: 'GET',
    action: 'getDefandantsStatus'
  },
];
